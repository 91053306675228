import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { EnvironmentSpecificService } from '../../environment-specific.service';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
};

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  apiUrl: any;
  apiUrl2: any;
  constructor(private http: HttpClient, 
    private _envSpecificSvc: EnvironmentSpecificService) {
    this.apiUrl = `${_envSpecificSvc.envSpecific.apiURL}/settings`;
    this.apiUrl2 =  `${_envSpecificSvc.envSpecific.apiURL}/system`;
  }

  test() {
    return this.http.post(`${this.apiUrl}`, "data");
  }
  testDbConnection(conSett: any) {
    return this.http.post(`${this.apiUrl2}/testdbconnection`, conSett, httpOptions);
  }
  saveDbSetting(dbSett: any) {
    return this.http.post(`${this.apiUrl2}/saveDbSetting`, dbSett, httpOptions);
  }

  testStorageConnection(ssSett: any) {
    return this.http.post(`${this.apiUrl}/teststorageconnection`, ssSett, httpOptions);
  }
  saveStorageSetting(ssSett: any) {
    return this.http.post(`${this.apiUrl}/storage`, ssSett, httpOptions);
  }
  saveSmtpSetting(smtpSett: any) {
    return this.http.post(`${this.apiUrl}/email`, smtpSett, httpOptions);
  }
  sendMail(email: any) {
    return this.http.post(`${this.apiUrl}/sendTestEmail`, email, httpOptions);
  }
  saveApiSetting(payload: any) {
    return this.http.post(`${this.apiUrl}/api-config`, payload, httpOptions);
  }
  getSetting(type: APIType): Observable<any> {
    return this.http.get(`${this.apiUrl}/${type}`).pipe(catchError(this.handleError("getSetting")));
  }
  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      console.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}

export enum APIType {
  document,
  googleMaps,
  googleRecaptcha,
  smartRedaction,
  accessCode,
  authMethod,
  paypal,
  email,
  storage,
  msal
}
