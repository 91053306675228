import { Injectable, NgZone, ElementRef } from "@angular/core";
import { Observable, of } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { catchError } from "rxjs/operators";
import { environment } from "environments/environment";
import { EnvironmentSpecificService } from '../../environment-specific.service';

@Injectable({
    providedIn: 'root'
})
export class ReCaptchaService {
    reCaptchaVerified: boolean;
    apiUrl: any;

    constructor(private ngZone: NgZone,
        private http: HttpClient, 
        private _envSpecificSvc: EnvironmentSpecificService
        ) {
        //this.apiUrl = environment.apiURL.concat('/auth');
        this.apiUrl = `${_envSpecificSvc.envSpecific.apiURL}/auth`;
    }
    renderReCaptch(captchaRef2: ElementRef, sitekey: string) {
        const grecaptcha = (window as any).grecaptcha;

        grecaptcha.render(captchaRef2.nativeElement, {
            'sitekey': sitekey,
            'callback': this.vertifyResponse.bind(this)
        });
    }

    // addRecaptchaScript() {
    //     window['grecaptchaCallback'] = () => {
    //         this.renderReCaptch();
    //     }
    //     (function (d, s, id, obj) {
    //         var js, fjs = d.getElementsByTagName(s)[0];
    //         if (d.getElementById(id)) { obj.renderReCaptch(); return; }
    //         js = d.createElement(s); js.id = id;
    //         js.src = "https://www.google.com/recaptcha/api.js?onload=grecaptchaCallback&amp;render=explicit";
    //         fjs.parentNode.insertBefore(js, fjs);
    //     }(document, 'script', 'recaptcha-jssdk', this));

    // }

    vertifyResponse(responceObject: any): any {
        this.validateRecaptcha(responceObject).subscribe(result => {
            return this.ngZone.run(() => {
                if (!result) {
                    const grecaptcha = (window as any).grecaptcha;
                    grecaptcha.reset();
                    return of(false);
                } else {
                    return of(true);
                }
            })
        });
    }

    reCapchaExpired() {
        const grecaptcha = (window as any).grecaptcha;
        grecaptcha.reset();
        this.reCaptchaVerified = false;
    }

    getRecaptcha(): Observable<any> {
        return this.http.get(`${this.apiUrl}/recaptcha`).pipe(catchError(this.handleError('getRecaptcha', [])));
    }

    validateRecaptcha(token: any): Observable<any> {
        return this.http.post(`${this.apiUrl}/validaterecaptcha`, { content: token });
    }

    /**
 * Handle Http operation that failed.
 * Let the app continue.
 * @param operation - name of the operation that failed
 * @param result - optional value to return as the observable result
 */
    private handleError<T>(operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {

            // TODO: send the error to remote logging infrastructure
            console.error(error); // log to console instead

            // TODO: better job of transforming error for user consumption
            console.log(`${operation} failed: ${error.message}`);

            // Let the app keep running by returning an empty result.
            return of(result as T);
        };
    }

}